import { useRef, useEffect } from 'react';
import Nav from '../Nav/Nav';
import { useState } from 'react';
import './Background.css';

import NET from 'vanta/dist/vanta.net.min';

// https://www.vantajs.com/

function Background(){

  const myRef = useRef();
  const [ backgroundObj, setbackgroundObj ] = useState(0);

  const initialState = {
    mouseControls: true,
    touchControls: true,
    gyroControls: false,
    minHeight: 400,
    minWidth: 150.00,
    scale: 0,
    points: 10.00,
    maxDistance: 23.00,
    scaleMobile: 1.00,
    backgroundColor: 0x00000,  
    color: 0x40c824
  }

  useEffect(() => {

    console.log("Background.js UseEffect runs....");
    console.log(backgroundObj)

    if (!backgroundObj) { 

      setbackgroundObj(NET({
        el: myRef.current,
        ...initialState
      }))

    }

    return () => {
      if (backgroundObj) backgroundObj.destroy()
    }

  },[backgroundObj,initialState]);


  // Change The color when pressing button
  function changeColor(){

    const effect = NET({
      el: myRef.current
    })

    // Later, when you want to update an animation in progress with new options

    effect.setOptions({
      ...initialState, 
      color: 0x3ff3ff,
    })

    // Clear to Update
    if (backgroundObj) backgroundObj.destroy()

    // Later, if the container changes size and you want to force Vanta to redraw at the new canvas size
   // effect.resize()

  }

  console.log("Background Component Rendered...")

  return (
    <section ref={myRef} className="main-section" style={{height: 400}}>
      {/* <ModalNav/> */}
      <Nav/>
      <div className='container'>
        <div className="row">
          <div className="col-12 col-md-6 main-section__heading">
              <h2>The NET, an extension of human potential.</h2>
              <h4>- Abraham Montoya</h4> 
          </div>
        </div>
        {/* <button onClick={changeColor}>Change Color</button> */}
      </div>
    </section>
  )

}

export default Background;