// import GitHubCalendar from 'react-github-calendar';
// import ReactTooltip from 'react-tooltip';

function Home (){

    const exampleTheme = {
        background: 'transparent',
        text: '#ffffff',
        grade4: 'hsl(118, 78%, 30%)',
        grade3: 'hsl(118, 78%, 46%)',
        grade2: 'hsl(118, 78%, 60%)',
        grade1: 'hsl(118, 78%, 80%)',
        grade0: '#000'
    };

    return (
        <div className="row">
            <div className="mt-3 text-center">
                <h3>Bio</h3>
                <div className="bio mb-4">
                    <div className="row">
                        <div className="col-lg-3"> 
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <img className="profile-pic img-fluid h-100 p-3 p-md-4 mb-3 m-md-auto" src="/images/profile-pic3.jpg" alt="Abraham"/>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <p className="p-2 p-md-4 m-0"> Hello and welcome! I'm Abraham Montoya, a senior UI Developer with over 10 years of experience. I specialize in translating design concepts into user-friendly aesthetically pleasing interfaces with excellent user experience. I’ve worked within design teams, along development platforms, and as a one-man-army in the banking and e-commerce industries. My expertise extends across multiple development environments such as Angular, Wordpress, and SalesForce Commerce Cloud. I'm versed with APIs interaction with applications, data access, and functionalities. I’ve also worked with backend integration such as user authentication, authorization, payment processing, and content management. My hobby is coding React applications. I am excited for new job opportunities and am currently looking for remote roles or willing to work on-site or hybrid in the San Antonio/Austin, Texas region.</p>
                                <a href="/images/Abraham_Montoya_2024_Resume.pdf" download="Abraham Montoya Resume" className="btn download mb-4 mt-3 mt-md-0" rel="noreferrer"><i class="fa fa-download"></i> Download Resume</a>
                            </div>
                        </div>

                    </div>
                </div>

    
                <h3> My GitHub </h3>
                <a href="https://github.com/AbeMont" className="mb-3 d-block" target="_blank" rel="noreferrer">
                    <i className="fa fa-3 fa-github m-2" aria-hidden="true"></i>
                    @AbeMont
                </a>                            

                {/* <GitHubCalendar 
                    username={'Abemont'} 
                    theme={exampleTheme}
                    blockSize={10}>
                    <ReactTooltip delayShow={50} html />
                </GitHubCalendar> */}
            </div>
        </div>
    )
}

export default Home;