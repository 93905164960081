import { BrowserRouter as Router, Route } from 'react-router-dom';

// Components
import Background from './components/Background/Background';

// Pages
import Home from './Pages/Home/Home';
import Projects from './Pages/Projects/Projects';
import Skills from './Pages/Skills/Skills';

function App() {
  return (
    <div className="App">
      <Router>
        <Background/>

          <div className="container">
            <Route path="/" component={Home} exact/>
            <Route path="/skills" component={Skills} exact/>
            <Route path="/projects" component={Projects} exact/>
          </div>
  
      </Router>
    </div>
  );
}

export default App;
