import './Skills.css';

function Skills(){
    return(
        <>
            <div className='row'>
                <div className='mt-4 mb-4 col-md-3 col-sm-6 text-center'>
                    <h2 className='mb-2'>Frontend</h2>
                    <img src='/images/icons/iconfinder_badge-html-5_317755.png' alt="HTML 5"/>
                    <img src='/images/icons/iconfinder_badge-css-3_317756.png' alt="CSS 3"/>
                    <img src='/images/icons/js-icon.png' alt="Javascript"/>
                    <img src='/images/icons/iconfinder_React.js_logo_1174949.png' alt="React"/>
                    <img src='/images/icons/angular.png' alt="Angular"/>
                </div>
                <div className='mt-4 mb-4 col-md-3 col-sm-6 text-center'>
                    <h2 className='mb-2'>Backend</h2>
                    <img src='/images/icons/node2.png' style={{width: "auto"}} alt="Node JS"/>
                </div>
                <div className='mt-4 mb-4 col-md-3 col-sm-6 text-center'>
                    <h2 className='mb-2'>Database</h2>
                    <img src='/images/icons/mongo-icon3.png' alt="Mongo DB"/>
                </div>
                <div className='mt-4 mb-4 col-md-3 col-sm-6 text-center'>
                    <h2 className='mb-2'>Dev Tools</h2>
                    <img src='/images/icons/github.png' alt="Github"/>
                    <img src='/images/icons/npm.png' alt="NPM"/>
                    <img src='/images/icons/iconfinder_288_Sass_logo_4375066.png' alt="Sass"/>
                </div>
                <div className='mt-4 mb-4 col-md-3 col-sm-6 text-center'>
                    <h2 className='mb-2'>CRM</h2>
                    <img src='/images/icons/salesforce.png' alt="salesforce"/>
                </div>
            </div>
            <div className="row">
                <a href="/images/Abraham_Montoya_2024_Resume.pdf" download="Abraham Montoya Resume" className="btn download" rel="noreferrer"><i class="fa fa-download"></i> Download Resume</a>
            </div>
        </>
    )
}

export default Skills;