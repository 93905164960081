import { Link } from 'react-router-dom';
import './Nav.css';

function Nav(){
    return (
        
        <nav className="nav">
            <div className='container'>
                <ul>
                    <li>
                        <Link to= "" className="nav__link">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to= "/skills" className="nav__link">
                            Skills
                        </Link>
                    </li>
                    <li>
                        <Link  to= "/projects" className="nav__link">
                            Projects
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
     
    )
}

export default Nav;